// variables

// share
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $c-lightGrey;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $c-lightGrey;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $c-lightGrey ;
}

input:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill {

  -webkit-text-fill-color: $c-greyer !important;
  //-webkit-box-shadow: 0 0 0px 1000px transparent  inset !important;
  background-color:transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s; //背景色透明  生效時長  過渡效果  啟用時延遲的時間
}

.questionInputBox {
  .questionInput {
    input,select{
      width: 100%;
      border: 1px solid #DEDEDF;
      border-radius: 4px;
      padding: 10px;
      height: 50px;
      background-color: white;
    }

    .questionInputName {

    }

    .questionInputPhone {

    }

    .questionInputEmail {

    }

    .questionInputTopic {
      select{
        color: $c-grey;
      }
    }
  }

  .questionTextarea {
    textarea{
      color: $c-grey;
      width: 100%;
      height: 82%;
      border: 1px solid #DEDEDF;
      border-radius: 4px;
      padding: 10px;
      resize: none;
    }
  }
}

.questionButton {
  .questionButtonClick {
    color: white;
    cursor: pointer;
    width: 210px;
    height: 55px;
    border: 3px solid $c-secondary;
    color:$c-secondary;
    border-radius: 10px;
    //background-color: #ECC9A3;
    transition:0.4s;
    @media (min-width: $m-pad) {
      &:hover{
        box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
      }
    }
  }
}

// questionInputInfo
.questionInputInfo{
  font-size: 0.9rem;
  //margin-left:10px;
  position: absolute;
  bottom: -25px;
  left: 25px;
}
.textareaInfo{
  font-size: 0.9rem;
  //margin-left:10px;
  position: absolute;
  bottom: 10px;
  left: 25px;
}
// main
@media (min-width: $m-pad) {
  margin-bottom: 80px;
}


@media (max-width: $m-pad) {
  margin-bottom: 60px;
}

@media (max-width: $ml-phone) {
  .questionInputBox {
    .questionInput{}
    .questionTextarea{
      textarea{
        height: 30vh;
      }
      .textareaInfo{
        position: absolute;
        bottom: -20px;
        left: 25px;
      }
    }
  }
}






