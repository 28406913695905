.about{
  @import "carousel";

  // main
  @media (min-width: $m-pc) {
    .aboutContents{
      margin-top: 80px;
    }
  }


  @media (max-width: 1199px) {
    .aboutContents{
      margin-top: 30px;
    }
  }

  @media (max-width: $m-pad) {
    .aboutContents{
    }
  }
  @media (max-width: $m-phonexs) {
    .aboutContents{
      margin-top: 1.5rem;
    }
  }

}

