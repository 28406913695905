// main
@media (min-width: $m-pad) {
  .homeService {
    padding-top: 40px;
    padding-bottom: 4vh;

    .serviceBox {
      img {
        //width:65px;
        //width: 90px;
        margin-bottom: 25px;
      }
    }
  }
}

@media (min-width: $m-pad) and (max-width: $m-pcs) {
  .homeService {
    padding-bottom: 0;

    .serviceBox {
      margin-bottom: 50px;

      img {
        //width: 60px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: $m-pad) {
  .homeService {
    padding-top: 50px;
    padding-bottom: 50px;

    .serviceBox {
      margin-bottom: 50px;

      img {
        width: 50px;
        //width: 80px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: $m-phone) {
  .homeService {
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
}

// serviceBox img

.homeService {
  .serviceBox {
    img {
      @media (min-width: 1680px) {
        height: 50%;
      }
      @media (max-width: 1680px) {
        height: 45%;
      }
      @media (max-width: 1440px) {
        height: 40%;
      }
      @media (max-width: 1280px) {
        height: 35%;
      }
      @media (max-width: $m-pcs) {
        height: 40%;
      }
      @media (max-width: $m-pad) {
        @include fullImg(100px,100px,contain)
      }
      @media (max-width: $m-phone) {
        //height: 40%;
      }
      @media (max-width: $m-phones) {
        @include fullImg(60%,70%,contain)
      }
      @media (max-width: $m-phonexs) {
        //height: 50%;
      }
    }
  }
}



