// *************************************
//
//   Tool
//   -> Tag-level settings
//
// *************************************

// -------------------------------------
//   Content
// -------------------------------------

// ----- color ----- //

.contentsColorPrimary {
  color: $c-primary;
}

.contentsColorSecondary {
  color: $c-secondary;
}

.contentsColorDark {
  color: $c-dark;
}

.contentsColorGreyer {
  color: $c-greyer;
}

.contentsColorGrey {
  color: $c-grey;
}

.contentsColorLightGrey {
  color: $c-lightGrey;
}

.contentsColorLight {
  color: $c-light;
}

.contentsColorLighter {
  color: $c-lighter;
}

.contentsColorSuccess {
  color: $c-success;
}

.contentsColorInfo {
  color: $c-info;
}

.contentsColorWarning {
  color: $c-warning;
}

.contentsColorDanger {
  color: $c-danger;
}

.contentsColorWhite {
  color: $c-white;
}

.contentsColorLighterGray {
  color: $c-lighterGray;
}

.contentsColorPurple {
  color: $c-purple;
}

.contentsColorBrown {
  color: $c-brown;
}

.contentsColorMidnightBlue {
  color: $c-midnightBlue;
}

// ----- Layout ----- //

.contentsLayoutPreWrap {
  white-space: pre-wrap;
}

// ----- Size ----- //

.contentsSize125 {
  font-size: 1.25rem;
}
.contentsSize15 {
  font-size: 1.5rem;
}

.contentsSize175 {
  font-size: 1.75rem;
}
.contentsSize2 {
  font-size: 2rem;
}
.contentsSize225 {
  font-size: 2.25rem;
}

// -------------------------------------
//   Container
// -------------------------------------

// ----- color ----- //

.containerColorPrimary {
  background-color: $c-primary;
}

.containerColorSecondary {
  background-color: $c-secondary;
}

.containerColorDark {
  background-color: $c-dark;
}

.containerColorGreyer {
  background-color: $c-greyer;
}

.containerColorGrey {
  background-color: $c-grey;
}

.containerColorLightGrey {
  background-color: $c-lightGrey;
}

.containerColorLight {
  background-color: $c-light;
}

.containerColorLighter {
  background-color: $c-lighter;
}

.containerColorSuccess {
  background-color: $c-success;
}

.containerColorInfo {
  background-color: $c-info;
}

.containerColorWarning {
  background-color: $c-warning;
}

.containerColorDanger {
  background-color: $c-danger;
}

.containerColorLighterGray {
  background-color: $c-lighterGray;
}

.containerColorPurple {
  background-color: $c-purple;
}

.containerColorBrown {
  background-color: $c-brown;
}

.containerColorMidnightBlue {
  background-color: $c-midnightBlue;
}

// ----- border Color ----- //

.containerBorderPrimary {
  border-color: $c-primary;
}

.containerBorderSecondary {
  border-color: $c-secondary;
}

.containerBorderDark {
  border-color: $c-dark;
}

.containerBorderGreyer {
  border-color: $c-greyer;
}

.containerBorderGrey {
  border-color: $c-grey;
}

.containerBorderLightGrey {
  border-color: $c-lightGrey;
}

.containerBorderLight {
  border-color: $c-light;
}

.containerBorderLighter {
  border-color: $c-lighter;
}

.containerBorderSuccess {
  border-color: $c-success;
}

.containerBorderInfo {
  border-color: $c-info;
}

.containerBorderWarning {
  border-color: $c-warning;
}

.containerBorderDanger {
  border-color: $c-danger;
}
.containerBorderLighterGray {
  border-color: $c-lighterGray;
}

.containerBorderPurple {
  border-color: $c-purple;
}

.containerBorderBrown {
  border-color: $c-brown;
}

.containerBorderMidnightBlue {
  border-color: $c-midnightBlue;
}

// ----- display ----- //

.containerDisplaySlow {
  transition: opacity 0.5s;
  animation-name: pageBuffering1;
  animation-duration: 0.8s;
  animation-timing-function: ease-out;
}
