// variables


// main


@media (min-width: $m-pad) {
  .footer {
    .footerLogo {
      padding: 6vw;
    }

    .footerText {

    }

    .footerMap {
      padding: 2.5vw;
    }
  }
}

@media (min-width: $m-pc) {
  .footer {
    .footerLogo {
      padding: 6vw;
    }

    .footerText {

    }

    .footerMap {
      padding: 2.5vw;

    }
  }
}



@media (min-width: $m-pad) and (max-width: $m-pcs) {
  .footer {
    .footerLogo {
      padding: 5vw;
    }
    .footerMap {
      padding-left: 0;
    }
  }
}


@media (max-width: 767px) {
  .footer {
    .footerLogo {
      padding: 5vw;
    }

    .footerText {
    }

    .footerMap {
      display: none;
    }
  }
}

@media (max-width: 575px) {
  .footer {
    .footerLogo {
      margin: 8.5vw 0;
      padding:0 4vw;
    }

    .footerText {
      margin: 7vw 0;
      width: -moz-fit-content;
      width: fit-content;
    }

  }
}

@media (max-width: $m-phonexs) {
  .footer {
    .copyrightText{
      white-space: pre-wrap ;
      small{
        display: none;
      }
    }
  }
}

