// variables
$headerHeight: 70px;
$headerHeightPhone: 60px;


// main
@media (min-width: $m-pad) {
  .header, .headerBox {
    width: 100vw;
    height: $headerHeight;
    top: 0;
    left: 0;
    z-index: 10;

    .headerImg {
      @include fullImg(250px, 100%, cover)
    }

    .navLink {
      .navLinkBar {
        top: 120%;
        width: 100%;
        height: 3px;
        border-radius: 4px;
        transition: 0.5s;
      }
    }
  }
}

@media (max-width: $m-pad) {
  .header, .headerBox {
    width: 100vw;
    height: $headerHeight;
    top: 0;
    left: 0;
    z-index: 10;

    .headerImgBox {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
      .headerImg {
        width: 250px;
        height: 56px;
      }
    }


    .navLink {
      display: none;
      a {
        display: none;
      }
    }

    .collapseButton {
      top: 0px;
      left: 0px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      width: $headerHeight;
      height: $headerHeight;
      z-index: 15;
      .collapseBar {
        border-radius: 3px;
        height: 2px;
        margin: 5px 0;
        width: 40px;
        transition: 0.5s;
        transform-origin: 46% 46%;

        &.collapseBar1.active {
          width: 40px;
          transform: rotate(45deg);
          margin: 0;
        }

        &.collapseBar2.active {
          display: none;
        }

        &.collapseBar3.active {
          width: 40px;
          margin: 0;
          transform: rotate(-45deg);
        }

      }
    }

    .mobilePhone{
      width: $headerHeight;
      height: $headerHeight;
      top:0;
      right:0;
      padding: 15px;
      z-index: 15;
      img{
        width: 30px;
        height: 30px;
      }
    }
  }
}

@media (max-width: $m-phone) {
  .header, .headerBox {
    height: $headerHeightPhone;
    .headerImgBox {

      .headerImg {
        width: 220px;
        height: 49px;
      }
    }

    .collapseButton {
      width: $headerHeightPhone;
      height: $headerHeightPhone;
      .collapseBar {
        margin: 3.5px 0;
        width: 25px;

        &.collapseBar1.active {
          width: 25px;
        }

        &.collapseBar3.active {
          width: 25px;
        }
      }
    }

    .mobilePhone{
      width: $headerHeightPhone;
      height: $headerHeightPhone;
      top:0;
      right:0;
      padding: 20px 15px 15px 15px;
      img{
        width: 25px;
        height: 25px;
      }
    }

  }
}

@media (max-width: 374px) {
  .header{
    .headerImgBox {
      .headerImg {
        width: 60vw;
        height: 49px;
      }
    }
  }
  .headerBox{
    top: 0;
    left: 0;
    //height: $headerHeightPhone;
  }
}

// mobileNav

.header {
  @media (min-width: 769px) {
    .mobileNav {
      display: none
    }
  }
  @media (max-width: 769px) {
    @import "mobileNav";
  }
}




