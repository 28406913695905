.team {
  // main
  @media (min-width: $m-pad) {
    margin-top: 100px;
    .teamBox {
      .teamFigure {
        figure {
          width: 250px;
          height: 330px;
          border-radius: 15px;
          overflow: hidden;

          img {
            @include fullImg(100%, 100%, cover);
          }
        }
      }
      .teamDetail {
        .teamName {
          cursor: pointer;
          transition: 0.5s;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }

  @media (min-width: $m-pad) and (max-width: $m-pc) {
    .teamBox {
      .teamFigure {
        figure {
          width: 220px;
          height: 290px;
          border-radius: 15px;
          overflow: hidden;

          img {
            @include fullImg(100%, 100%, cover);
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin-top: 50px;
    .teamBox {
      .teamFigure {
        figure {
          width: 250px;
          height: 330px;
          border-radius: 15px;
          overflow: hidden;

          img {
            @include fullImg(100%, 100%, cover);
          }
        }
      }

      .teamDetail {
        .teamTitle {
          -ms-flex-pack: center !important;
          justify-content: center !important;
        }

        .teamContents {
          text-align: center;
          -ms-flex-pack: center !important;
          justify-content: center !important;
        }
      }
    }
  }

  @media (max-width: $m-phone) {
    margin-top: 20px;
    .teamBox {
      .teamDetail {
        .teamTitle {
          width: 100vw;
          -ms-flex-pack: center !important;
          justify-content: center !important;
        }
      }
    }
  }

  @media (max-width: 320px) {
    //margin-top: 20px;
    .teamBox {
      .teamFigure {
        width: 100vw;
        display: flex;
        -ms-flex-pack: center !important;
        justify-content: center !important;
      }

      .teamDetail {
        .teamTitle {
        }

        .teamContents {
          p {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
