
.contact {

  margin-top: 100px;

 .information{
  @import "information";
}
  .question{
    @import "question";
  }


  // main
  @media (min-width: $m-pc) {

  }

  @media (min-width: $m-pad) and (max-width: $m-pc) {
  }

  @media (max-width: $m-pad) {
    margin-top: 40px;
  }

  @media (max-width: $m-phone) {
    margin-top: 20px;

  }
}


  @import "alert";