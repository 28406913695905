// variables


// main



@media (min-width: $m-pad) {
  .mobileTitle{
    display: none;
  }
}

@media (max-width: $m-pad) {
  .mobileTitle{
    display: block;
    color:$c-primary;
  }
}






