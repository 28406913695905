

.timeV1 {
  margin-top: 11vh;
  margin-bottom: 11vh;
  .timeBox{
    .timeDay{
      .timeDayTime{
        img{
          top: -40%;
          left: 30%;
          transform: rotate(45deg);
          opacity: 0.7;
        }
      }
    }
    .timeForm{
      .timeFormTime{
        border: 1px solid #C7C7C7;
        border-radius: 8px 0 0 8px;
        .timeFormTimeLine{
          width: 2px;
          height: 35px;
        }
        .timeFormTimeInnerBox{
          width: 100%;
          border-top: 1px solid #C7C7C7;
          border-bottom: 1px solid #C7C7C7;
          @media (min-width: $m-pad) {
              .timeFormTimeInnerText{
                display: inline-block;
              }
          }
        }
      }
      .timeFormVetBox{
        .timeFormVet{
          cursor: pointer;
        margin-bottom: 7vh;
          transition: 0.5s;
          @media (min-width: $m-pad) {
            &:hover{
              opacity: 0.5;
            }
          }
        }
      }
      .timeFormVetCard{
        width: 12vw;
        top: 0%;
        right: 3%;
        border-radius: 8px;
        box-shadow: 0px 3px 6px rgba(0,0,0,0.15);
        figure{
          border-radius: 8px;
          overflow: hidden;
          img{
            @include fullImg()
          }
        }
      }
    }

    .timeFooter{
      .timeFooterSelect{
      border-right: 1px solid #C7C7C7;
        .timeFooterSelectItem{
          cursor: pointer;
          transition: .5s;
          @media (min-width: $m-pc) {
            &:hover{
              opacity: 0.5;
            }
          }
        }
      }
      .timeFooterHint{}
    }
  }

  // main
  @media (min-width: $m-pc) {

  }

  @media (min-width: $m-pcs) and (max-width: $m-pc) {
    padding-left: 15vw;
    padding-right: 15vw;
    .timeFormVetCard{
      left:0;
      transform: translateX(71vw);
    }
  }
  @media (min-width: $m-pad) and (max-width: $m-pcs) {
    padding-left: 13vw;
    padding-right: 13vw;
    .timeFormVetCard{
      left:0;
      transform: translateX(74.5vw);
      figure{
        border-radius: 8px;
        overflow: hidden;
        img{
          @include fullImg()
        }
      }
    }
  }

  @media (max-width: $m-pad) {
    margin-top: 8vh;
    margin-bottom: 8vh;
    padding-left: 2vw;
    padding-right: 2vw;
    .timeTitle{
      font-size: 1.25rem;
    }
    .timeBox{

      .timeDay{
        .timeDayTime{
        }
      }
      .timeForm {
        .timeFormTime {
        }
        .timeFormTimeInnerBox {
            p{
              display: block;
            }
            small {
              display: block;
            }
          }
        .timeFormVetCard{
          display: none;
        }
      }
      }
  }
  @media (max-width: 575px) {
    margin-top: 5vh;
    margin-bottom: 5vh;
    .timeBox{
      .timeBoxScroll {
        padding-left: 30px;
        overflow: hidden;
        overflow-x: scroll;
      }
      .timeDay{
        width:150vw;
        .timeDayTime{
          img{
            top: 0;
            left: 25%;
            transform: rotate(30deg);
            opacity: 0.7;
          }
        }
      }
      .timeForm{
        width:150vw;
        .timeFormTime{
          .timeFormTimeLine{
            height: 30px;
          }
        }
        .timeFormVetBox{
          .timeFormVet{
            margin-bottom: 50px;
          }
        }
      }
      .timeFooter{
        .timeFooterSelect{
          display: none;
          border:none;
          .timeFooterSelectItem{
            display: none;
          }
        }
        .timeFooterHint{
          justify-content: center;
          transform: translateX(-8%);
        }
      }
    }
  }
}

