// *************************************
//
//   Base
//   -> Tag-level settings
//
// *************************************

html {

  //scroll-behavior: smooth;
  //font-size: 14px;
  //background: $c-background;
  //color: $c-base;
  //font-family: $b-fontFamily;
  //font-size: $b-fontSize;
  //line-height: $b-lineHeight;
}

html, body{
  font-family: Arial,"Microsoft JhengHei","微軟正黑體",sans-serif !important;
}

@media (min-width: $m-phone) {
  html {
    font-size: 14px;
  }
}
@media (max-width: $m-phone) {
  html {
    font-size: 15px;
  }
}@media (max-width: $m-phones) {
  html {
    font-size: 14px;
  }
}



body {
  font-size: 100%;
}

a {
  color: $c-greyer;
  transition: .3s;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  @media (min-width: 1169px) {
    &:hover {
      text-decoration: none;
      opacity: 0.5;
      color: $c-dark;
    }
  }
}


// -------------------------------------
//   Block Content
// -------------------------------------

ul, p {
  //margin-bottom: $b-space;
  //margin-top: 0;
}


li {
  //margin-bottom: $b-space-s;
  //margin-top: 0;
}


// ----- Headers ----- //


h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}
h6{
  font-size: 1rem;
}

p{
  font-size: 1.125rem;
}
small{
  font-size: 1rem;

}
// h1 = 2.25rem = 36px
// h2 = 2rem = 32px
// h3 = 1.75rem = 28px
// h4 = 1.5rem = 24px
// h5 = 1.25rem = 20px
// h6 = 1rem = 16px
// p = 1rem = 16px

.p1 {
  font-size: 2.25rem;
}

.p2 {
  font-size: 2rem;
}

.p3 {
  font-size: 1.75rem;
}

.p4 {
  font-size: 1.5rem;
}

.p5 {
  font-size: 1.25rem;
}
.p6{
  font-size: 1rem;
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  //font-family: $b-fontFamily-heading;
  //font-weight: bold;
  //line-height: 1.2;
  //margin-bottom: $b-space-xs;
  //margin-top: 0;
}


// -------------------------------------
//   Inline Content
// -------------------------------------

// ----- forms ----- //

textarea:focus, input:focus,select:focus ,button:focus{
  outline: none;
}



