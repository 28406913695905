// variables
$homeCarouselHeight: 65vh;

// share
.carouselContents {
  .h2, .h5 {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }

  .h5 {
    line-height: 1.65;
  }
}


// main
@media (min-width: $m-pad) {
  .homeCarousel {
    .carouselFigure {
      width: 100%;
      height: $homeCarouselHeight;
      overflow: hidden;

      img {
        @include fullImg(100%, 100%, cover)
      }
    }

    .carouselContents {
      top: 35vh;
      right: 0;
      padding: 40px 50px 40px 20px;

      .h2 {
        margin-bottom: 15px;
      }

      .h5 {
        margin-bottom: 35px;
      }
    }

    .carouselBars {
      .carouselBarBox {
        width: 30px;
        height: 30px;
        cursor: pointer;

        .carouselBar {
          width: 100%;
          height: 3px;
          transition: 0.5s;
        }
      }
    }
  }
}

@media (min-width: $m-pc) {
  .homeCarousel {
    .carouselFigure {
      width: 100%;
      //height: $homeCarouselHeight;
      height: 470px;
      overflow: hidden;

      img {
        @include fullImg(100%, 45vw, cover)
      }
    }

    .carouselContents {
      top: 45%;
      right: 0;
      padding: 40px 30px 40px 20px;
      transform: translate(0);
      text-align: left;

    }

  }
}


@media (max-width: $m-pad) {
  .homeCarousel {
    .carouselFigure {
      width: 100%;
      height: $homeCarouselHeight;
      overflow: hidden;

      img {
        @include fullImg(100%, $homeCarouselHeight, cover)
      }
    }
    .carouselBars {
      .carouselBarBox {
        width: 30px;
        height: 30px;
        cursor: pointer;

        .carouselBar {
          width: 100%;
          height: 3px;
          transition: 0.5s;
        }
      }
    }

  }
}

@media (max-width: $m-phone) {
  .homeCarousel {
    .carouselContents {
      .h2 {
        margin-bottom: 15px;
        font-size: 1.75rem;
      }

      .h5 {
        line-height: 1.4;
        margin-bottom: 35px;
        font-size: 1.15rem;
      }
    }
  }
}


.homeCarousel {
  // carouselFigure
  .carouselFigure {
    @media (min-width: 1680px) {
      height: 700px;
      img {
        @include fullImg(100%, 800px, cover)
      }
    }
    @media (max-width: 1680px) {
      height: 630px;
      img {
        @include fullImg(100%, 700px, cover)
      }
    }
    @media (max-width: 1440px) {
      height: 550px;
      img {
        @include fullImg(100%, 700px, cover)
      }
    }
    @media (max-width: 1280px) {
      height: 440px;
      img {
        @include fullImg(100%, 600px, cover)
      }
    }
    @media (max-width: $m-pcs) {
      height: 400px;
      img {
        @include fullImg(100%, 400px, cover)
      }
    }
    @media (max-width: $m-pad) {
      height: 600px;
      img {
        @include fullImg(100%, 600px, cover)
      }
    }
    @media (max-width: $m-phone) {
      height: 500px;
      img {
        @include fullImg(100%, 500px, cover)
      }
    }
    @media (max-width: $m-phones) {
      height: 400px;
      img {
        @include fullImg(100%, 400px, cover)
      }
    }

  }

  // carouselContents
  .carouselContents {
      @media (min-width: 1680px) {
        top: 400px;
        right: 0;
        padding: 40px 50px 40px 20px;

        .title1 {
          font-size: 2.75rem;
          margin-bottom: 15px;
        }

        .title2 {
          font-size: 2rem;
          margin-bottom: 35px;
        }
      }
      @media (max-width: 1680px) {
        top: 380px;
        right: 0;
        padding: 40px 50px 40px 20px;
        .title1 {
          font-size: 2.5rem;
          margin-bottom: 15px;
        }

        .title2 {
          font-size: 1.8rem;
          margin-bottom: 35px;
        }
      }
      @media (max-width: 1440px) {
        top: 320px;
        right: 0;
        padding: 40px 50px 40px 20px;

        .title1 {
          font-size: 2.25rem;
          margin-bottom: 15px;
        }

        .title2 {
          font-size: 1.5rem;
          margin-bottom: 35px;
        }
      }
      @media (max-width: 1280px) {
        top: 220px;
        right: 0;
        padding: 40px 50px 40px 20px;

        .title1 {
          font-size: 2rem;
          margin-bottom: 15px;
        }

        .title2 {
          font-size: 1.25rem;
          margin-bottom: 35px;
        }
      }
      @media (max-width: $m-pcs) {
        width: 100vw;
        top: 200px;
        padding: 40px 5px 40px 5px;
        text-align: center;
        .title1 {
          font-size: 2.25rem;
          margin-bottom: 15px;
        }

        .title2 {
          font-size: 1.5rem;
          margin-bottom: 35px;
        }
      }
      @media (max-width: $m-pad) {
        top: 380px;
        padding: 40px 20px 40px 20px;

        .title1 {
          font-size: 2.25rem;
          margin-bottom: 15px;
        }

        .title2 {
          font-size: 1.5rem;
          margin-bottom: 35px;
        }
      }
      @media (max-width: $m-phone) {
        top: 360px;
        padding: 0px 30px 10px 30px;

        .title1 {
          font-size: 1.75rem;
          margin-bottom: 15px;
        }

        .title2 {
          font-size: 1rem;
          margin-bottom: 35px;
        }
      }
      @media (max-width: $m-phones) {
        top: 270px;
        padding: 0 15px 40px 15px;

        .title1 {
          font-size: 1.7rem;
          margin-bottom: 15px;
        }

        .title2 {
          font-size: 1.1rem;
          margin-bottom: 35px;
        }
      }

    @media (min-width: 450px) {
      white-space: pre-wrap;
    }

  }
}

//@media (min-width: 769px) and (max-width: 850px) {
//  top: 50%;
//  left: 50%;
//  padding: 40px 0px;
//  text-align: center;
//  transform: translate(-50%);
//  width: 100vw;
//}




