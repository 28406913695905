// -------------------------------------
//   Colors
// -------------------------------------

// ----- Base ----- //
$c-primary: #74A19C;
$c-secondary: #ECC9A3;
$c-dark: #1A1311;
$c-greyer: #4E4E4E;
$c-grey: #707070;
$c-lightGrey: #c5c5c5;
$c-light: #F4EEE6;
$c-lighter: #F8F6F4;
$c-success: #8FB2C6;
$c-info: #2ea9df;
$c-warning: #ffb11b;
$c-danger: #d36c6c;
$c-white: #f4f4f6;
$c-lighterGray: #e4e3e3;
$c-purple: #e0a5f8;
$c-brown: #96660E;
$c-midnightBlue: #212E57;

// -------------------------------------
//   Base
// -------------------------------------

// ----- Section Margin Top ----- //
$b-marginTop: 100px;

// ----- Borders & Box Shadow ----- //

$b-borderRadius: 3px;
$b-borderStyle: solid;
$b-borderWidth: 2px;
$b-border: $b-borderWidth $b-borderStyle $c-grey;
$b-boxShadow: 0 2px 0 rgba($c-dark, 0.25);

// ----- Typography ----- //

$b-fontFamily-heading: 'OpenSans', sans-serif;
$b-fontFamily: 'OpenSans', sans-serif;
$b-fontSize: 16px;
$b-fontSize-s: 75%;
$b-fontSize-m: 90%;
$b-fontSize-l: 115%;
$b-lineHeight: 1.5;

// ----- Sizing ----- //

$b-space: (20px);
$b-space-s: 0.5 * $b-space;
$b-space-l: 2 * $b-space;
$b-space-xl: 4 * $b-space;

// -------------------------------------
//   Components
// -------------------------------------

// ----- Grid ----- //

$g-s: 'em(480px)';
$g-m: 'em(800px)';
$g-l: 'em(1024px)';

// -------------------------------------
//   Structures
// -------------------------------------

// ----- Dropdown ----- //

// example：$dropdown-width: em(200px)

// ----- media size ----- //

$m-pc: 1200px;
$m-pcs: 992px;
$m-pad: 768px;
$m-phone: 576px;
$m-phones: 375px;
$m-phonexs: 320px;


$ml-pc: 1199px;
$ml-pcs: 991px;
$ml-pad: 767px;
$ml-phone: 575px;
$ml-phones: 374px;