// variables


// main
@media (min-width: $m-pad) {
  .aboutCarousel {
    margin-top: 80px;
    width: 100%;
    //height: 55vh;
    overflow: hidden;
    border-radius: 15px;

    img {
      //@include fullImg(100%,55vh,cover)
    }
  }

  .aboutCarouselBar {
    .aboutCarouselBarBox {
      width: 30px;
      height: 30px;
      cursor: pointer;

      .aboutCarouselBarPress {
        width: 100%;
        height: 3px;
        transition: 0.5s;
      }
    }
  }
}

@media (min-width: $m-pad) and (max-width: 1199px) {
  .aboutCarousel {
    margin-top: 0;
    width: 100%;
    //height: 70vh;
    overflow: hidden;
    border-radius: 0;

    img {
      //@include fullImg(100%,115vh,cover)
    }
  }

  .aboutCarouselBar {
    .aboutCarouselBarBox {
      .aboutCarouselBarPress {

      }
    }
  }
}

@media (max-width: $m-pad) {
  .aboutCarousel {
    width: 100%;
    height: 60vw;
    overflow: hidden;
    border-radius: 0;

    img {
      @include fullImg(100%, 60vw, cover)
    }
  }
  .aboutCarouselBar {
    transform: translateY(-50px);

    .aboutCarouselBarBox {
      width: 30px;
      height: 30px;
      cursor: pointer;

      .aboutCarouselBarPress {
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
        width: 100%;
        height: 3px;
        transition: 0.5s;
      }
    }
  }
}

@media (max-width: $m-phone) {
}


// aboutCarousel img
.aboutCarousel {

  @media (min-width: 1199px) {
    height: 30vw;
    img {
      @include fullImg(100%, 30vw, cover)
    }
  }
  @media (min-width: $m-pcs) and (max-width: $ml-pc) {
    height: 40vw;
    img {
      @include fullImg(100%, 100%, cover)
    }
  }
  @media (min-width: $m-pad) and (max-width: $m-pcs) {
    height: 50vw;
    img {
      @include fullImg(100%, 100%, cover)
    }
  }
}




