// variables

// share
.carouselFigure{
  margin-bottom: 50px;
  overflow: hidden;
  width: 100%;
}


// main
@media (min-width: $m-pc) {
.carouselFigure{
  margin-bottom: 70px;
  height: 30vw;
  img{
  }
}
}

@media (min-width: $m-pad) and (max-width: $m-pc) {
  .carouselFigure{
    height: 30vw;
    img{
      @include fullImg(100%,40vw,cover)
    }
  }
  }

@media (max-width: $m-pad) {
  .carouselFigure{
    height: 35vw;
    img{
      @include fullImg(100%,40vw,cover)
    }
  }
}

@media (max-width: $m-phone) {
  .carouselFigure{
    height: 45vw;
    img{
      @include fullImg(100%,45vw,cover)
    }
  }
}






