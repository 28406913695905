
// main
@media (min-width: $m-pad) {
  .homeFeatures{
    .featuresIconBox{
      width: 100%;
      //height: 1px;
      height: 0px;
      margin: 8vh 0 8vh 0;
      .featuresIcon{
        padding: 0 18px 0 18px;
        left: 50%;
        transform: translate(0,-50%);

      }
    }
    p{
      line-height: 1.75;
    }
  }
}
@media (min-width: $m-pad) and (max-width: $m-pcs){
  .homeFeatures{
    .featuresIconBox{

      .featuresIcon{
        padding: 0 10px 0 10px;

      }
    }
  }
}

@media (max-width: $m-pad) {
  .homeFeatures{
    .featuresIconBox{
      width: 100%;

      //height: 1px;
      height: 0px;
      margin: 8vh 0 8vh 0;
      .featuresIcon{
        padding: 0 18px 0 18px;
        left: 50%;
        transform: translate(0,-50%);
        img{
          width:70%;
        }
      }
    }
    p {
      line-height: 1.75;
    }

  }
}
@media (max-width: $m-phone) {
  .homeFeatures{
    .featuresIconBox{
      .featuresIcon{
        img{
          width:67%;
        }
      }
    }
  }
}

// featureIcon img

.homeFeatures{
  .featuresIconBox {
    .featuresIcon {
      @media (min-width: 1680px) {
        img{
          width:85%;
        }
      }
      @media (max-width: 1680px) {
        img {
          width:80%;
        }
      }
      @media (max-width: 1440px) {
        img {
          width:75%;
        }
      }
      @media (max-width: 1280px) {
        img {
          width:65%;
        }
      }
      @media (max-width: $m-pcs) {
        img {
          width:65%;
        }
      }
      @media (max-width: $m-pad) {
        img {
          //width:65%;
        }
      }
      @media (max-width: $m-phone) {
        img {
          //width:70%;
        }
      }
      @media (max-width: $m-phones) {
        img {
          //width:70%;
        }
      }
      @media (max-width: $m-phonexs) {
        img {
          //width:65%;
        }
      }
    }
  }}


