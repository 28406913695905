.service {

  // carousel
  .serviceCarousel {
    @import "carousel";
  }

  // Contents
  .serviceContentsBox {
    .serviceContentsContainer {

      padding-bottom: 130px;

      .serviceContents {
        img{
          //height: 110px;
          height: 100px;
        }
        .serviceContentsLine {
          width: 30%;
          height: 1px;
          margin: 30px 0 30px 0;

        }

        .serviceContentsTitle {
          margin-bottom: 35px;
        }

        .serviceContentsIntro {
       line-height: 1.75;
        }
      }

      // media
      @media (min-width: $m-pad) and (max-width: $m-pc) {
        padding-bottom: 100px;
      }


      @media (max-width: $m-pad) {
        padding-bottom: 50px;

      }

      @media (max-width: $m-phone) {
        .serviceContents {
          .serviceContentsTitle {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

