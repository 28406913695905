// variables


// main

.mobileNav{
  top: $headerHeight;
  left: 0;
  width: 100vw;
  height: 0vh;
  overflow: hidden;
  z-index: 12;
  background-color:rgba(0,0,0,0.5);
  transition: 0.7s ease-in;
  &.active{
    top: $headerHeight;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 12;
    background-color:rgba(0,0,0,0.5);
  }
  .mobileNavBar{
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
  }
  a{
    transition: .5s ;
    &:active,&:focus,&:hover{
      color: white;
      text-shadow:1px 1px 3px rgba(0,0,0,0.3);
    }
  }
  }

@media (max-width: $m-phone) {
  .mobileNav{
    top: $headerHeightPhone;
    &.active{
      top: $headerHeightPhone;
    }
  }
}






