// variables

// share
.informationMap{
  width:1000px;
  height: 500px;
}
.informationContentsBox{

}

// main
@media (min-width: $m-pc) {
}

@media (min-width: $m-pad) and (max-width: $m-pc) {
  }

@media (max-width: 767px) {
  .informationMap{
    height: 450px;
    margin-bottom: 30px;
  }
  .informationContentsBox{
    display: flex;
  }
}

@media (max-width: $m-phone) {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;

  .informationContentsBox{
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    .informationContentsDetail,.informationContentsParking{
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
}






