@keyframes pageBuffering {
  from{
    opacity: 0.25;
  }
  to{
    opacity: 1;
  }
}
@keyframes pageBuffering1 {
  from{
    opacity: 0.1;
  }
  to{
    opacity: 1;
  }
}