// variables



// main
@media (min-width: $m-pad) {
   .divideLine{
   height: 1px;
     margin: 8vh 0 8vh 0;
     .divideLineBox{
       padding: 0 50px 0 50px;
       left: 50%;
       transform: translate(-50%,-50%);
     }
   }
}

@media (max-width: $m-pad) {
  .divideLine{
    height: 1px;
    margin: 8vh 0 8vh 0;
    .divideLineBox{
      padding: 0 50px 0 50px;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

@media (max-width: $m-phone) {
  .divideLine{
    .divideLineBox{
      padding: 0 30px 0 30px;
    }
  }
}
@media (max-width: 374px) {
  .divideLine{
    .divideLineBox{
      padding: 0 5vw;
    }
  }
}





