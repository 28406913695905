@media (min-width: 300px) {
  .wrapper {
    transition: opacity 0.5s;
    animation-name: pageBuffering;
    animation-duration: 1.1s;
    animation-timing-function: ease-out;
  }
  .pageBuffering {
    transition: opacity 0.5s;
    //@include css3(animation-name,pageBuffering);
    animation-name: pageBuffering;
    //@include css3(animation-duration,1.1s);
    animation-duration: 0.8s;
    animation-timing-function: ease-out;
  }
}
@media (min-width: $m-pad) {
  .mobileLayout {
    display: none;
  }
}
