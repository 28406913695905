

.walkAlert {
  transition: 0.5s;
  .alertBox{
    transition: 0.5s;
    width: 0;
    height: 0;
  }
}


// walkAlert
  .walkAlert {
    width:100vw;
    height:100vh;
    top:0;
    left:0;
    z-index:15;
    .alertBox.active{
      width: 540px;
      height: 360px;
      border-radius: 10px;
      filter: drop-shadow(1px 1px 10px rgba(0,0,0,0.3));
      @media (max-width: $m-phone) {
        width: 90vw;
        height: 340px;
        //height: 360px;
      }
    }
    .alertContents{
      height: 280px;
      @media (max-width: $m-phone) {
        height: 265px;
      }
      img{
        width: 200px;
        margin-bottom: 10px;
        @media (max-width: $m-phone) {
          width: 160px;
        }
      }
    }
    .alertButton{
      .buttonConfirm,.buttonModify{
        transition: 0.4s;
        width: 100px;
        height: 40px;
        border-radius: 5px;
        border-style: solid;
        border-width: 2px;
        cursor: pointer;
        @media (min-width: $m-pad) {
          &:hover{
            box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
          }
        }
      }
      .buttonConfirm.active{

      }
      .buttonModify.active{
        //transform: translateX(1000px);
        //display: none;
        width: 0;
        height: 0;
        border: none;
        overflow: hidden;
      }
    }






    @media (min-width: 1680px) {
    }
    @media (max-width: 1680px) {
    }
    @media (max-width: 1440px) {
    }
    @media (max-width: $ml-pc) {

    }
    @media (max-width: $m-pcs) {

    }
    @media (max-width: $m-pad) {

    }
    @media (max-width: $m-phone) {

    }
    @media (max-width: $m-phones) {

    }
    @media (max-width: $m-phonexs) {

    }
  }


