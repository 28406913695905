
.timeV2 {
  margin-top: 2vw;
  margin-bottom: 2vw;
  @media (max-width: $m-pad) {
    margin-top: 0;
  }

  .timeTitle {
    @media (max-width: $m-pad) {
      display: none;
    }
  }

  .timeBox {
    .timeDay {
      .timeDayTime {
        cursor: pointer;
        @media (min-width: $m-pad) {
          &:hover {
            opacity: 0.5;
          }
        }

        img {
          top: -40%;
          left: 30%;
          transform: rotate(45deg);
          opacity: 0.7;
        }
      }
    }

    .timeBoxScroll {
      .timeForm {
        .timeFormLine {
          .timeFormVet {
            cursor: pointer;
            //margin-bottom: 7vh;
            transition: 0.5s;
            @media (min-width: 769px) {
              &:hover {
                opacity: 0.5;
              }
            }
          }

          .timeFormWork {
          }
        }
      }

      .timeVetCard {
        width: 13vw;
        top: 0%;
        left: 2%;
        border-radius: 8px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

        figure {
          border-radius: 8px;
          overflow: hidden;

          img {
            @include fullImg()
          }
        }
        @media (max-width: $m-pcs) {
          width: 130px;
        }
        @media (max-width: $m-pad) {
          display: none;
        }
      }
    }
  }
}

// variable


// timeDay & timeForm & timeFooter & timeChangeBlock Padding
.timeV2 {
  .timeBox {
    @media (min-width: 1680px) {
    }
    @media (max-width: 1680px) {
    }
    @media (max-width: 1440px) {
      .timeBoxScroll {
        .timeForm {
          //padding-bottom: 10px;
        }
      }
    }
    @media (min-width: $ml-pc) {
      .timeChangeBlock{
        padding-left: 13vw;
        padding-right: 13vw;
      }
    }
    @media (max-width: $ml-pc) {
      $timeFormPadding1200: 150px;
      .timeDay {
        padding-left: $timeFormPadding1200;
        padding-right: $timeFormPadding1200;
      }
      .timeBoxScroll {
        .timeForm {
          padding-left: $timeFormPadding1200;
          padding-right: $timeFormPadding1200;
        }
      }
      .timeFooter{
        padding-left: 12vw;
        padding-right: 10vw;
      }
      .timeChangeBlock{
        padding: 0 $timeFormPadding1200;
      }
    }
    @media (max-width: $m-pcs) {
      $timeFormPadding992: 150px;
      .timeDay {
        padding-left: $timeFormPadding992;
        padding-right: $timeFormPadding992;
      }
      .timeBoxScroll {
        .timeForm {
          padding-left: $timeFormPadding992;
          padding-right: $timeFormPadding992;
        }
      }
      .timeFooter{
        padding-left: 0;
        padding-right: 0;
      }
      .timeChangeBlock{
        padding-left: $timeFormPadding992;
        padding-right: $timeFormPadding992;
      }
    }
    @media (max-width: $m-pad) {
      $timeFormPadding768: 30px;
      .timeDay {
        padding-left: $timeFormPadding768;
        padding-right: $timeFormPadding768;
      }
      .timeBoxScroll {
        .timeForm {
          padding-left: $timeFormPadding768;
          padding-right: $timeFormPadding768;
        }
      }
      .timeFooter{

      }
      .timeChangeBlock{
        padding: 0 $timeFormPadding768;
      }
    }
    @media (max-width: $m-phone) {
      $timeFormPadding576: 30px;
      .timeDay {
        padding-left: $timeFormPadding576;
        padding-right: $timeFormPadding576;
      }
      .timeBoxScroll {
        .timeForm {
          padding-left: $timeFormPadding576;
          padding-right: $timeFormPadding576;
        }
      }
      .timeFooter{
        padding-left: $timeFormPadding576;
        padding-right: $timeFormPadding576;
      }
    }
    @media (max-width: $ml-phones) {
      $timeFormPadding375: 5vw;
      .timeDay {
        padding-left: $timeFormPadding375;
        padding-right: $timeFormPadding375;
      }
      .timeBoxScroll {
        .timeForm {
          padding-left: $timeFormPadding375;
          padding-right: $timeFormPadding375;
        }
      }
      .timeFooter{
        padding-left: $timeFormPadding375;
        padding-right: $timeFormPadding375;
      }
      .timeChangeBlock{
        padding: 0 $timeFormPadding375;
      }
    }
    @media (max-width: $m-phonexs) {
      $timeFormPadding320: 2vw;
      .timeFooter{
        padding-left: $timeFormPadding320;
        padding-right: $timeFormPadding320;
      }
      .timeChangeBlock{
        padding: 0 $timeFormPadding320;
      }
    }
  }
}

// timeFormLine
.timeV2 {
  .timeBox {
    .timeBoxScroll {
      .timeForm {
        .timeFormLine {
          @media (min-width: 1680px) {
          }
          @media (max-width: 1680px) {
            .timeFormWork {
              .timeFormWorkHalf {
                transform: translateY(10px);
              }
            }
          }
          @media (max-width: 1440px) {
            .timeFormVet {
              padding-bottom: 5px;

              p {
                margin-bottom: 0rem;
              }
            }
            .timeFormWork {
              padding-bottom: 5px;
            }
          }
          @media (max-width: $ml-pc) {
            .timeFormVet {
              flex-direction: column;
              padding-bottom: 5px;

              p {
                margin-bottom: 0rem;
                //&.h5 {
                //  margin-bottom: 0.05rem;
                //}
                //
                //margin-bottom: 0.5rem;
              }
            }
            .timeFormWork {
              padding-bottom: 5px;

              .timeFormWorkHalf {
                transform: translateY(15px);
              }
            }
          }
          @media (max-width: $m-pcs) {
          }
          @media (max-width: $m-pad) {
          }
          @media (max-width: $m-phone) {
            .timeFormVet {
              flex-direction: row;

              small {
                display: none;
              }

              p {
                align-self: flex-end;
                margin-bottom: 0.5rem;

                &.h5 {
                  letter-spacing: 3px;
                  margin-bottom: 0.5rem;
                }
              }
            }
            .timeFormWork {
              .timeFormWorkHalf {
                transform: translateY(32px);

                small {
                  display: none;
                }
              }
            }
          }
          @media (max-width: $ml-phones) {
            .timeFormWork {
              .timeFormWorkHalf {
                .timeFormWorkHalfTime{
                  position: absolute;
                  top:0;
                  left:50%;
                  transform: translate(-50%,50%);
                }
              }
            }
          }
          @media (max-width: $m-phonexs) {
          }
        }
      }
    }
  }
}

// timeFooter timeFooterBox 1.timeFooterTime 2.timeFooterHint
.timeV2 {
  .timeBox {
    .timeFooter {
      .timeFooterBox {
        @media (min-width: 1680px) {
          }
          @media (max-width: 1680px) {
          }
          @media (max-width: 1440px) {

          }
          @media (max-width: $ml-pc) {

          }
          @media (max-width: $m-pcs) {
            width: fit-content;
              margin-left: auto;
            margin-right: auto;
            .timeFooterTimeWork,.timeFooterTimeHalf{
              justify-content: center;
            }
          }
          @media (max-width: $m-pad) {
          }
          @media (max-width: $m-phone) {

          }
          @media (max-width: $m-phones) {
          }
          @media (max-width: $m-phonexs) {
            white-space: pre-wrap ;
            .timeFooterTimeWork{
              justify-content: center;
              .footerTimeWorkCircle{
                -ms-flex-item-align: start !important;
                align-self: flex-start !important;
                margin-top: 3px;
              }
            }
            .timeFooterTimeHalf{
              justify-content: center;
            }
          }
      }
    }
  }
}

// timeFormScroll
.timeV2 {
  .timeBox {
    @media (max-width: $m-phone) {
      .timeBoxScroll {
        box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.2);
        height: 51vh;
        overflow: hidden;
        overflow-y: scroll;
        .timeForm{
          margin-bottom: 30vh;
        }
      }
    }
  }
}

// timeChange
.timeV2 {
  .timeBox {
    .timeChangeContainer {
      
    }
    @media (max-width: $m-phone) {
      .timeChangeContainer {
       
      }
    }
  }
}



